@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Metropolis-Regular.ttf") format("truetype");
}

@layer components {
  .colorGradient {
    @apply text-transparent bg-clip-text bg-gradient-to-r from-pink-600 to-purple-600;
  }
  .bgGradient {
    @apply bg-gradient-to-r from-pink-600 to-purple-600
  }
}


.video-halo {
  display: none;
}

.messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 15px;
  display: none;
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .message { display: inline-block;}
  .video-halo { display: flex;}
}

.mine {
  align-items: flex-end;
}

.mine .message {
  margin-left: 25%;
  background:rgb(243 232 255);
  background-attachment: fixed;
  position: relative;
}

.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background:rgb(243 232 255);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: #F9FAFB;
  border-bottom-left-radius: 10px;
}

.ballOne {
  animation-delay: 0.1s;
}
.ballTwo {
  animation-delay: 0.7s;
}
.ballThree {
  animation-delay: 1.2s;
}

iframe {
  border-radius: 0.75rem;
}

.display-linebreak {
  white-space: pre-line;
}